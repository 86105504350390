import React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'
import Seo from "../components/seo"
import pudding_newspapers from '../images/external/pudding_newspapers.png'
import a_data from '../images/external/a_data_politique_we_do_data.jpg'
import pudding_black_white from '../images/external/pudding_black_white_issue.png'
import maarten_regions_split from '../images/external/maarten_regions_split.jpg'
import douleur_des_belges from '../images/external/douleur_des_belges.png'

class ProjectInfos extends React.Component {
  render() {
    return (
      <article>
        <header>
          <span className="date">Vu ailleurs</span>
          <h2><a href={this.props.url} target="_blank" rel="noopener noreferrer">
          {this.props.title}
          </a></h2>
        </header>
        <a className="image fit" href={this.props.url} target="_blank" rel="noopener noreferrer">
        <img src={this.props.image} alt={this.props.alt} />
        </a>
        <p>
        {this.props.description}
        </p>
        <p><em>{this.props.additional_infos}</em></p>
        <ul className="actions special">
          <li><a className="button" href={this.props.url} target="_blank" rel="noopener noreferrer">
         Voir</a></li>
        </ul>
      </article>
    )
  }
}
class VuAilleursPage extends React.Component {
  render() {
    return (
      <Layout>
      <Seo title="Vu ailleurs" />
            <div id="main">
                <article className="post featured">
                  Petit tour de projets qui m'inspirent et donnent des idées, tantôt pour le fond, tantôt pour la forme.
                  <br/>Merci à leurs auteurs&nbsp;!
                </article>
                <section className="posts">
                  <ProjectInfos
                    url = "https://pudding.cool/2018/01/chyrons/"
                    title = "Comment CNN, MSNBC &amp; Fox News couvrent l'info"
                    image = {pudding_newspapers}
                    alt = "Différences entre les mots employés par les journaux américains"
                    description = {
                      <>
                      Un projet qui met en évidence les divergences dans le traitement de
                      l'actualité entre 3 médias américains en analysant le vocabulaire employé par ceux-ci.
                      </>
                    }
                    additional_infos = {["Réalisé par ",<em>Charlie Smart</em>]}
                  />
                  <ProjectInfos
                    url = "https://lesjours.fr/obsessions/data-politique/"
                    title = {["À data sur la",<br/>," politique"]}
                    image = {a_data}
                    alt = "Couverture des enquête data sur le paysage politique en France"
                    description = {
                      <>
                      Une série &quot;d'enquêtes data&quot; sur le paysage politique en France, 
                      sur base de données de l'Assemblée, de données Twitter ou encore de données Wikipédia.
                      </>
                    }
                    additional_infos = {["Réalisé par ",<a href="https://wedodata.fr/" target="_blank" rel="noopener noreferrer">Wedodata</a>]}
                  />
                  <ProjectInfos
                    url = "https://pudding.cool/2019/04/eu-regions/"
                    title = "Pourquoi Budapest, Varsovie et la Lituanie se sont séparées en deux ?"
                    alt = "Représentation des régions de l'UE, classées par développement économique"
                    image = {maarten_regions_split}
                    description = {
                      <>
                      Un contenu dynamique tout en cartes et en données qui explique pourquoi il est préférable pour
                      certaines régions de se diviser afin de bénéficier des aides de l'UE.
                      </>
                    }
                    additional_infos = {["Réalisé par ",<a href="https://www.maartenlambrechts.com/" target="_blank" rel="noopener noreferrer">Maarten Lambrechts</a>]}
                  />
                  <ProjectInfos
                    url = "https://pudding.cool/2017/10/asne/"
                    title = {["Les journaux :",<br/>," Une question en noir et blanc"]}
                    image = {pudding_black_white}
                    alt = "Représentation de la (non-)parité noir/blanc dans les médias américains"
                    description = {
                      <>
                      Une analyse qui met en évidence la sur-représentation des personnes de couleur
                      blanche dans les rédactions des médias américains.
                      </>
                    }
                    additional_infos = {["Réalisé par ",<a href="https://www.notion.so/M-Daniels-1697763234d54ea7a46201064ff43914" target="_blank" rel="noopener noreferrer">Matt Daniels</a>," et ",<a href="https://amber.rbind.io/" target="_blank" rel="noopener noreferrer">Amber Thomas</a>]}
                  />

                  <ProjectInfos
                    url = "https://medor.coop/nos-series/la-douleur-des-belges/opioides-datavisualisations/"
                    title = {["La douleur des",<br/>," Belges"]}
                    image = {douleur_des_belges}
                    alt = "Carte de l'utilisation d'opioïdes en Belgique"
                    description = {
                      <>
                      Une enquête de Médor sur la consommation d'opioïdes en Belgique, 
                      l'augmentation de cette consommation depuis 20 ans et les disparités Nord-Sud.
                      </>
                    }
                    additional_infos = {["Réalisé par ",<a href="https://jetpack.ai/" target="_blank" rel="noopener noreferrer">JetPack.AI</a>]}
                  />
                  <article>
                    <header>
                      <span className="date">Projet futur</span>
                      <h2>Un projet<br/> en vue&nbsp;?</h2>
                    </header>
                    <p>Ces projets vous donnent des idées et vous cherchez quelqu'un pour en discuter ou les réaliser&nbsp;?
                    </p>
                    <ul className="actions special">
                      <li><Link className="button" to="/me-contacter/">Me contacter</Link></li>
                    </ul>
                    <blockquote>
                    Grand amateur de thé en hiver, et de bières en été,
                    les chances que je refuse de discuter d'un projet ou d'une idée
                    autour de l'un ou l'autre sont infimes&hellip;
                    </blockquote>
                  </article>
                </section>

            </div>
      </Layout>
    )
  }
}

export default VuAilleursPage